$(window).on('load', function() {
  
    if (!((parent != undefined) && (parent.window != undefined) && (parent.window.it != undefined)))
  

    $('#img-slider').slick({
        centerMode: true,
        centerPadding: '0px',
        autoplay: true,
        autoplaySpeed: 9000,
        arrows: true,
        slidesToShow: 3,
        pauseOnFocus: false,
        dots: true,
        variableWidth: true,
        focusOnSelect: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    variableWidth: false,
                }
                },
              
        ]
    });

    $('#img-slider-login').slick({
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        adaptiveHeight: false,
        arrows: false,
        autoplay: true,
      });

    if (document.getElementById('img-slider') != undefined) {
        $('#img-slider').attr('style','opacity:1')
    }

    $('#img-ebt').slick({
        centerMode: true,
        centerPadding: '0px',
        autoplay: true,
        autoplaySpeed: 9000,
        arrows: true,
        slidesToShow: 1,
        pauseOnFocus: false,
        dots: true,
        focusOnSelect: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
                },
              
        ]
    });

});