$(function() {
    $('#subscribe-newsletter').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success) {
                    $('#newsletter-step2').hide();
                    $('#mail2').val('');
                    $('#success-message').show();
                }
                else {
                    showMessageBox('danger', msg['ERR_INVALIDMAIL']);
                }
            },
            failed: function(data) {
            },
            complete: function(data) {
                grecaptcha.reset();
            }
        });
    });

    // newsletter show recapcha
    $('#newsletter-arrow').on('click', function() {
        $('#newsletter-step2').fadeIn(1300);
    });

    // newsletter show recapcha
    $('#next-step').on('click', function() {
        $('.step-1').hide();
        $('.step-2').fadeIn(1300);
    });
    $('#prev-step').on('click', function() {
        $('.step-2').hide();
        $('.step-1').fadeIn(1300);
    });
});

function subscribeNewsletterCaptcha() {
    $('#subscribe-newsletter button[type=submit]').prop('disabled', false);
}