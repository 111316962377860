var messageBoxDuration = 1500;

function showMessageBox(type, message, extra) {
    var boxType = type;
    var content = '';
    var topMessage = ''
    if (extra) {
        content = '<div>' + extra.content + '</div>';
    }
    if (boxType == 'danger') {
        topMessage = "ERROR"
    } else {
        topMessage = "SUCCESS" 
    }
    var messageBox = `
    <div class="alert-container fade in relative">
        <div class="alert-${boxType}">
            <img id="close-error" class="close-sample" src="/skins/b2bopificio/images/icons/cancel.svg"></img>
            <img class="alert-image" src="/skins/b2bopificio/images/icons/${boxType}.svg"></img>
            <div class="alert">${message}${content}</div>
        </div>
    </div>`;

    $('div.alert-box').html(messageBox);
    $('#close-error').on('click', function () {
        $('div.alert-box').html('');
        $('.alert-box').hide()
        $('body').removeClass('block-scroll')
    });
    $('body').addClass('block-scroll')
    $('.alert-box').show();
    setTimeout(function() {
        $('div.alert-box').html('');
        $('.alert-box').hide();
        $('body').removeClass('block-scroll')
    }, messageBoxDuration);
}

function showLargeMessageBox(type, message, duration) {
    duration = duration || 10000;
    var boxType = type;
    var topMessage = ''
    if (boxType == 'danger') {
        topMessage = "ERROR"
    } else {
        topMessage = "SUCCESS" 
    }
    message = message.split('\n').map(part => `<p>${part}</p>`).join('');

    var messageBox = `
        <div class="alert-container fade in relative">
            <div class="alert-${boxType}">
                <img id="close-error" class="close-sample" src="/skins/b2bopificio/images/icons/cancel.svg"></img>
                <img class="alert-image" src="/skins/b2bopificio/images/icons/${boxType}.svg"></img>
                <div class="alert">${message}</div>
            </div>
        </div>`;

    $('div.alert-box').html(messageBox);
    $('#close-error').on('click', function () {
        $('div.alert-box').html('');
        $('.alert-box').hide()
        $('body').removeClass('block-scroll')
    });
    $('body').addClass('block-scroll')
    $('.alert-box').show();
    setTimeout(function() {
        $('div.alert-box').html('');
        $('.alert-box').hide();
        $('body').removeClass('block-scroll')
    }, duration);
}

function showQuestionBox(message, onConfirm, onClose=()=>{}) {
    const messageBox = `
    <div class="alert-container fade in relative">
        <div class="alert-danger">
            <img id="close-error" class="close-sample" src="/skins/b2bopificio/images/icons/cancel.svg"></img>
            <img class="alert-image" src="/skins/b2bopificio/images/icons/danger.svg"></img>
            <div class="alert">${message}</div>
            <div class="flex margin-top-10">
                <input type="button" class="btn btn-third width-100 margin-right-10 close-button" value="${buttons_close}">
                <input type="button" class="btn btn-primary width-100 margin-left-10 confirm-button" value="${buttons_confirm}">
            </div>
        </div>
    </div>`;
    
    $('div.alert-box').html(messageBox);
    $('#close-error, .close-button').on('click', function () {
        $('div.alert-box').html('');
        $('.alert-box').hide();
        $('body').removeClass('block-scroll');
        onClose();
    });
    $('.confirm-button').on('click', onConfirm);
    $('body').addClass('block-scroll')
    $('.alert-box').show();
}

$(function() {
    
    var errorFound = getUrlParam("error");

    if (errorFound) {
        $('body').addClass('block-scroll')
        $('.alert-box').show();
        $('.close-sample').on('click', function () {
            $('div.alert-box').html('');
            $('.alert-box').hide();
            $('body').removeClass('block-scroll')
        });
        setTimeout(function() {
            $('div.alert-box').html('');
            $('.alert-box').hide();
            $('body').removeClass('block-scroll')
        }, messageBoxDuration);
    }

});