$(function() {
    'use strict';

    initIntlTelInput();

    const currentCountryOption = $( "#country" ).find('option:selected');
    const currentCountry = currentCountryOption.data('eu');
    const currentCountryId = currentCountryOption.val() >> 0;
    const COUNTRY_UK = [77, 253];

    if (currentCountry == 'EU' || COUNTRY_UK.includes(currentCountryId)) {
        $('#pec-sdi-div').addClass('hide');
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
    } else if (currentCountry == 'IT') {
        $('#pec-sdi-div').removeClass('hide');
        $('#pec-div').removeClass('hide');
        $('#sdi-div').removeClass('hide');
    } else if (currentCountry != undefined) {
        $('#pec-sdi-div').addClass('hide');
        $('#pec-div').addClass('hide');
        $('#sdi-div').addClass('hide');
    }

    $('#copyLink').click(function() {
        var ret = copyTextToClipboard($('#linkToCopy').val())
        if (ret) {
            $('#copyLink').html('Link copiato!')
        }
    })

    $('#copy-dispatch').on('click', function(event) {

        document.getElementById('addressee').value = document.getElementById('addressee_disp').value
        document.getElementById('cap').value = document.getElementById('cap_disp').value
        document.getElementById('city').value = document.getElementById('city_disp').value
        document.getElementById('number').value = document.getElementById('number_disp').value
        document.getElementById('prov').value = document.getElementById('prov_disp').value
        document.getElementById('street').value = document.getElementById('street_disp').value

        $('#country').val(document.getElementById('country_disp').value)

        var currentCountry = $( "#country" ).find('option:selected').data('eu');

        if (currentCountry == 'EU') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').attr('required','required');
        } else if (currentCountry == 'IT') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').removeClass('hide');
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#cfpiva').attr('required','required');
        } else if (currentCountry != undefined) {
            $('#cfpiva-div').addClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').removeAttr('required');
        }

    });

    $( "#country" ).change(function() {
        var selected = $(this).find('option:selected');
        var requiredVat = selected.data('eu'); 

        if (requiredVat == 'EU') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').attr('required','required');
            $('#cfpiva').val("")
        } else if (requiredVat == 'IT') {
            $('#cfpiva-div').removeClass('hide');
            $('#pec-sdi-div').removeClass('hide');
            $('#pec-div').removeClass('hide');
            $('#sdi-div').removeClass('hide');
            $('#cfpiva').attr('required','required');
            $('#cfpiva').val("")
        } else {
            $('#cfpiva-div').addClass('hide');
            $('#pec-sdi-div').addClass('hide');
            $('#pec-div').addClass('hide');
            $('#sdi-div').addClass('hide');
            $('#cfpiva').removeAttr('required');
            $('#cfpiva').val("")
        }
    }); 
    
    function submitUserUpdateForm(form) {
        const $form = $(form);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                    setTimeout(() => window.location.reload(), 1500);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    }

    $('#user-update-data-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-firm-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-dispatch-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-invoice-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#password-change-form').submit(function(event) {
        event.preventDefault();
        if ($('#password1').val.length>8) {
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                beforeSend: function(data) {
                    $(this).find(':input').prop('disabled', true);
                },
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', data.data);
                    } else {
                        showMessageBox('danger', data.data);
                    }
                },
                complete: function(data) {
                    $(this).find(':input').prop('disabled', false);
                }
            });
        } else {
            showMessageBox('danger', msg['ERR_SHORTPASSWORD']);
        }
    });
    $('#1to2step').click(function(event) {
        var form = $('#create-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                $('#idnewotp').val(data.id);
                $('#qrcode').attr('src','data:image/png;base64, ' + data.data);
                $('#firstStep').hide();
                $('#secondStep').show();
                console.log(data);
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#enableOTP').click(function(event) {
        var form = $('#enable-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#deleteOTP').click(function(event) {
        var form = $('#delete-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
	});
});

function initIntlTelInput() {
    const cel = $('[id*="cel"]').not('[type="hidden"]');
    const cel_prefix = $('[id*="cel"][type="hidden"]');

    if (cel.length > 0 && cel_prefix.length > 0) {
        cel.val(cel_prefix.val() + cel.val());
        const iti = window.intlTelInput(cel[0], {
            separateDialCode: true,
            utilsScript: '/skins/b2bopificio/js/vendor/utils.js',
        });
    
        cel.on('countrychange', function () {
            const prefix = '+' + iti.getSelectedCountryData().dialCode;
            $('[id*="cel_prefix"]').val(prefix);
        });
    }
}