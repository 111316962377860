$(function() {

    $('.js-open-submenu').on('click', function (e) {
        e.preventDefault();
        var submenuId = $(this).data('open');
        $('.js-mobile-menu').hide();
        $('.close-offcanvas-menu').hide();
        $('#' + submenuId).addClass('js-submenu-open');
        $('.js-mobile-menu-back').fadeIn();
    });
    $('.js-mobile-menu-back').on('click', function (e) {
        e.preventDefault();
        $('.js-submenu').removeClass('js-submenu-open');
        $('.js-mobile-menu-back').hide();
        $('.js-mobile-menu').fadeIn();
        $('.close-offcanvas-menu').fadeIn();
    });

    $('#mobile-menu-trigger').on('click', function () {
        $(this).children('img').toggleClass('hidden');
        $('.mobile-menu, .mobile-menu__search').fadeToggle();
        $('body').toggleClass('overflow-hidden');
    });

    $('.mobile-menu__language').on('click', function () {
        $(this).siblings('ul').slideToggle();
    });
    
    // menu
    $('.is-hover a').on('mouseover', function () {
        var idx = $(this).parent().index() + 2;
        $('.info:nth-child(' + idx + ')').addClass('info-visible');
        $('.info:nth-child(' + idx + ')').siblings().removeClass('info-visible');
      });
      
      $('.dropdown').on('mouseout', function () {
        $('.info:nth-child(1)').addClass('info-visible').siblings().removeClass('info-visible');
      });
});


//video ebt


jQuery(document).ready(function() {
    var video = jQuery('#ebt-video');
    jQuery('#play-button').on('click', function() {
        jQuery(this).hide();
        video.get(0).play();
        video.css("opacity", "1");
    });
});


  // sticky menu
  $(window).scroll(function() {
    const header = $('.header-main')
    if(!header.hasClass('hide')) {
        const topBanner = $('.top-banner');
        if ($(this).scrollTop() > (topBanner.length > 0 ? topBanner.outerHeight() : 0)) {
            const headerHeight = header.height();
            header.addClass("sticky");
            $('.body-container').attr('style', `margin-top:${headerHeight}px`);
        } else {
            header.removeClass("sticky");
            $('.body-container').attr('style', '');
        }
    }
})