var rewixUpdateCart = (elem, remove=false) => {
    const RewixIsDefined = typeof Rewix !== 'undefined';

    if (RewixIsDefined) {
        if (Rewix.trackUpdateCart) {
            if (remove)
                matomoUpdateCartRemove(elem);
            else 
                matomoUpdateCart(elem)
        }
        else if (Rewix.trackAddToCart) {
            Rewix.trackAddToCart('', 1);
        }
    }
}

function matomoUpdateCart(elem) {
    const qtys = $(elem).find('*').toArray().filter(input => $(input).data('matomo'));

    for (let input of qtys) {
        const $input = $(input);
        const finalQuantity = $input.val();
        if (!Number.isNaN(parseInt(finalQuantity))) {
            const {productId, name, modelId, modelSKU, initQuantity, price} = $input.data('matomo');
            Rewix.trackUpdateCart(productId, name, modelId, modelSKU, initQuantity, finalQuantity, price);
            $input.data('matomo', {
                productId: productId,
                name: name,
                modelId: modelId,
                modelSKU: modelSKU,
                initQuantity: finalQuantity,
                price: price
            });
        }
    } 
}

function matomoUpdateCartRemove(elem) {
    const qtys = $(elem).find('*').toArray().filter(input => $(input).data('matomo'));

    for (let input of qtys) {
        const $input = $(input);
        if (!Number.isNaN(parseInt($input.val()))) {
            const finalQuantity = 0;
            const {productId, name, modelId, modelSKU, initQuantity, price} = $input.data('matomo');
            Rewix.trackUpdateCart(productId, name, modelId, modelSKU, initQuantity, finalQuantity, price);
        }
    } 
}