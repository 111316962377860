'use strict';

var dropCookie = true;
var cookieDuration = 365;
var cookieName = 'complianceCookie';
var cookieValue = 'on';

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, '', -1);
}

function exists(selector) {
    try {
        return $(selector).length > 0;
    }
    catch {
        return false;
    }
}

function yes(condition) {
    return !!condition || condition == 'true' || condition != 'false';
}

function not(condition) {
    return !condition || condition == 'false' || condition != 'true';
}

function copyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
  
    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
  
    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';
  
    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;
  
    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
  
    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
  
  
    textArea.value = text;
  
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      var successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      return true
    } catch (err) {
      document.body.removeChild(textArea);
      return false
    }
}

function loadProvinces(countryId) {
    return new Promise((resolve) => {
        $.ajax({
            method: 'GET',
            url: `/restful/countries/provinces?countryId=${countryId}`,
            success: function(data) {
                resolve(data.results);
            },
            error: function() {
                resolve([]);
            }
        });
    });
}

$(function() {
    if (exists('[name=eori_code]')) {
        $('select[name*=country]')
            .on('change', checkIfEoriRequired)
            .each(checkIfEoriRequired);

        function checkIfEoriRequired() {
            const value = $(this).val() >> 0;
            const COUNTRY_UK = [77, 253];

            if (COUNTRY_UK.includes(value)) {
                const eori = $('[name=eori_code]');
                if (!eori.is(':required')) {
                    const label = eori.closest('.input-text').find('label');
                    eori.prop('required', 'required');
                    label.text(label.text() + '*');
                }
            }
            else {
                const eori = $('[name=eori_code]');
                const label = eori.closest('.input-text').find('label');
                eori.prop('required', false);
                label.text(label.text().replace('*', ''));
            }
        }
    }

    if (exists('[name*=cfpiva]')) {
        $('select[name*=country]')
            .on('change', checkIfCFPIVARequired)
            .each(checkIfCFPIVARequired);

        function checkIfCFPIVARequired() {
            const option = $(this).find('option:selected');
            const value = option.val() >> 0;
            const code = option.data('eu');
            const COUNTRY_UK = [77, 253];

            if (code == 'EU' || code == 'IT' || COUNTRY_UK.includes(value)) {
                const cfpiva = $('[name*=cfpiva]');
                const label = cfpiva.siblings('label').html();
                if (!label.includes(' *')) {
                    cfpiva.prop('required', 'required');
                    cfpiva.siblings('label').html(label + ' *');
                }
            }
            else {
                const cfpiva = $('[name*=cfpiva]');
                const label = cfpiva.siblings('label').html();
                cfpiva.prop('required', false);
                cfpiva.siblings('label').html(label.replace(' *', ''));
            }
        }
    }

    if (getUrlParam('errorCode') == '263') {
        $('#login-form').addClass('hide');
        $('#login-form-otp').removeClass('hide');
    }

    if (sessionStorage.getItem("side-popup") == null) {
        if ($('#side-popup').length > 0) {
            setTimeout(function(){ 
                sessionStorage.setItem("side-popup", "true");
                $('#side-popup').removeClass('hide');
            }, 20000);
        }
    }

    $('#close-side-popup').click(function() {
        $('#side-popup').addClass('hide');
    })

    if (window.location.hash) {
        $(window).scrollTop($(window).scrollTop() - $('header').outerHeight());
    }

    $('#billing-address-form, #user-update-invoice-form').find('[name*=country_id]').on('change', function() {
        const $this = $(this);
        const form = $(this).closest('form');
        const value = parseInt($this.val());
        if (value == 108) {
            form.find('[name=fiscal_code]')
                .prop('disabled', false)
                .closest('.columns')
                    .removeClass('hide');
        }
        else {
            form.find('[name=fiscal_code]')
                .prop('disabled', true)
                .closest('.columns')
                    .addClass('hide');
        }
    });
});

function GTMCallback(callback) {
    return (containerId) => {
        if ((!containerId || containerId.startsWith("GTM-")) && typeof callback === "function") {
            callback();
        }
    }
}

function pushGTMEventWithCallback(timeout, options) {
    const dataLayer = window.dataLayer ?? [];

    let callbackExecuted = false;
    dataLayer.push({
        ...options,
        ...(options.eventCallback
            ? {
                eventCallback: GTMCallback(() => {
                    if (!callbackExecuted) {
                        callbackExecuted = true;
                        options.eventCallback();
                    }
                })
            }
            : {}
        )
    });

    if (options.eventCallback) {
        setTimeout(() => {
            if (!callbackExecuted) {
                callbackExecuted = true;
                options.eventCallback();
            }
        }, timeout);
    }
}