$(function() {

    $('.datepicker').pickadate()
    $('.timepicker').pickatime()

    var number = 0;

    $('#sortpicture1').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-1').addClass('hide');
                    $('#image-uploaded-1').removeClass('hide');
                    $('#link-image1').val(JSON.parse(php_script_response).link);
                    number++;
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#sortpicture2').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-2').addClass('hide');
                    $('#image-uploaded-2').removeClass('hide');
                    $('#link-image2').val(JSON.parse(php_script_response).link);
                    number++
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#sortpicture3').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-3').addClass('hide');
                    $('#image-uploaded-3').removeClass('hide');
                    $('#link-image3').val(JSON.parse(php_script_response).link);
                    number++
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#sortpicture4').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-4').addClass('hide');
                    $('#image-uploaded-4').removeClass('hide');
                    $('#link-image4').val(JSON.parse(php_script_response).link);
                    number++
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#sortpicture5').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-5').addClass('hide');
                    $('#image-uploaded-5').removeClass('hide');
                    $('#link-image5').val(JSON.parse(php_script_response).link);
                    number++
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#profile-consent-form').submit(function(event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents':[]
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function(data) {

            },
            success: function(data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });

    $('#to-budget-private').on('click', function () {

        to_budget_private();

    });

    $('#back-personal-private').on('click', function () {

        to_personal_private();
        
    });

    $('#to-description-private').on('click', function () {

        to_description_private();

    });

    $('#to-send-private').on('click', function () {

        to_send_private(number);
        
    });

    $('.private-personal').on('click', function () {

        to_personal_private();
        
    });

    $('.private-budget').on('click', function () {

        to_budget_private();
        
    });

    $('.private-send').on('click', function () {

        to_send_private();
        
    });

});

function findCheckedInput(name) {

    var result = '';
    var x = document.getElementsByName(name);
    var i;
        for (i = 0; i < x.length; i++) {
            if (x[i].checked == true) {
                result = result.concat(x[i].value) + ' '    
            }
        }
    return result;
}


function contactFormRecaptchaBusiness() {

    $('#contact-form-professionista').validate({
        errorPlacement: function(error, element) {
            error.appendTo("");
          }
    });

    if ($("#privacy-professionista").is(":checked") == true) {

    if ($('#contact-form-professionista').valid()) {

    $.ajax({
        type: $('#contact-form-professionista').attr('method'),
        url: $('#contact-form-professionista').attr('action'),
        data: $('#contact-form-professionista').serialize(),
        dataType: 'json',
        beforeSend: function(data) {
        },
        success: function(data) {
          if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
          } else {
            showMessageBox('danger', data.errors.message);
          }
        },
        failed: function(data) {
          showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          grecaptcha.reset();
        },
        complete: function(data) {
            
        }
    })
 
    } else {
        grecaptcha.reset();
    }

    } else {
        showMessageBox("danger", msg['ERR_PRIVACY'])
        grecaptcha.reset();
    }

}

function to_personal_private() {

    $('#private-contact-budget').addClass('hide');
    $('#private-contact-personal').removeClass('hide');
    $('#private-contact-send').addClass('hide');
    $('#private-contact-space-description').addClass('hide');

}

function to_budget_private() {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').removeClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-send').addClass('hide');
        $('#private-contact-space-description').addClass('hide');

    }

}

function to_send_private(number) {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-send').removeClass('hide');

        document.getElementById('private-contact-name-lastname').innerHTML = $('#private-name').val();
        document.getElementById('private-contact-email').innerHTML = $('#private-email').val();
        document.getElementById('private-contact-phone').innerHTML = $('#private-phone').val();
        document.getElementById('private-contact-city').innerHTML = $('#private-city').val();

        document.getElementById('private-contact-description').innerHTML = findCheckedInput('rinnova');

        document.getElementById('private-contact-colors').innerHTML = $('#private-space-colors').val();
        document.getElementById('private-contact-budget-input').innerHTML = findCheckedInput('budget');

        if (number != 0) {

            $('#number-count').html($('#number-count').html() + number);

        } else {

            $('#private-image-div').addClass('hide');

        }

    }

}

function to_description_private() {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-send').addClass('hide');
        $('#private-contact-space-description').removeClass('hide');

    }

}

function contactRecaptchaCallbackPrivate() {

    if ($("#privacy-private").is(":checked") == true) {

    $.ajax({
        type: $('#private-contact').attr('method'),
        url: $('#private-contact').attr('action'),
        data: $('#private-contact').serialize(),
        dataType: 'json',
        beforeSend: function(data) {
        },
        success: function(data) {
            if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
            $('#private-contact-submit').addClass('private-button-disabled')
            } else {
            showMessageBox('danger', data.errors.message);
            }
        },
        failed: function(data) {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            $('#private-contact').foundation('close');
            grecaptcha.reset();
        },
        complete: function(data) {
            
        }
    })

    } else {
        showMessageBox("danger", msg['ERR_PRIVACY'])
        grecaptcha.reset();
    }
}

function contactFormRecaptcha() {
    if (document.getElementById('privacy').checked) {
        $('#contact-form-send input[type=submit]').prop('disabled', false);
        sendContact();
    } else {
        showMessageBox("danger", msg['ERR_PRIVACY']);
        grecaptcha.reset();
    }
}

function sendContact() {
    var $form = $('#contact-form, #contact-form-exhibition');

    if (document.getElementById('email').value != "") {

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded',
            beforeSend: function(data) {
    
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                grecaptcha.reset();
            }
        });

    } else {
        showMessageBox('danger', msg['ERR_FILLEMAILFIELD']);
        grecaptcha.reset();
    }

}