$(function() {

    $('#close-modal-wishlist').on('click', function () {
        $('#shareWishlistPopup').foundation('close');
    });

    $('.btn-wishlist-remove').on('click', function(event) {
        var product = $(this).parents('.wishlist-product');
        event.preventDefault();
        var oldModel = product.find('input').val();
        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/addtocart?wishlist=true',
            data: 'qty_model_' + oldModel + '=' + 0,
            dataType: 'json',
            success: function(data) {
                window.location.href = '/current/profile/wishlist';
            },
            error: function() {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('.carrier-form').on('change', function() {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // update cart quantity
    $('.cart-update').on('submit', function(event) {
        event.preventDefault();
        const $this = $(this);
        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    rewixUpdateCart($this)
                    window.location.href = '/current/cart';
                }
                else if (data.code == 'MIXED_INTANGIBLE') {
                    showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                }
                else if (data.code == 'MIXED_CART') {
                    showMessageBox('danger', msg['MSG_MIXED_CART']);
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                    showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                }
                else if (data.code == 'LOGIN_REQUIRED') {
                    location.href = '/' + $('html').attr('lang') + '/login';
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.btn-cart-update').on('click', function(event) {
        event.preventDefault();
        const $form = $(this).closest('form');
        $form.trigger('submit');
    });

    // remove cart size
    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        const $this = $(this);
        var data = {};
        data[$this.attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $this.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    const container = $this.closest('.qty-input-container');
                    rewixUpdateCart(container, true);
                    window.location.href = '/current/cart';
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // remove cart item
    $('.btn-cart-remove-all').on('click', function(event) {
        event.preventDefault();
        const $form = $(this);
        var currentTable = $form.attr('data-product-id');
        var inputs = $(currentTable).find('.i-number');
        var data = {};

        inputs.each(function () {
            data[$form.attr('name')] = 0;
        });

        $.ajax({
            type: 'POST',
            url: $form.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    const container = $form.closest('#cart-container');
                    rewixUpdateCart(container, true);
                    window.location.href = '/current/cart';
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });


    // Coupon form
    $(".burn-coupon-form").on('submit', function(event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    $('#gestpay-form').on('submit', function(e){
        e.preventDefault();
        var action = $(this).attr('action');
        $.ajax({
            type : 'POST',
            url : action,
            async: false,
            beforeSend : function(xhr) {
                xhr.setRequestHeader("Accept", "application/json")
            },
            dataType : "json",
            success : function(data){
                if(data.success){
                    window.location.href = data.message;
                }
            }
        });
    });


    // calculate each product total
    function singleProductQty() {
        $('.cart-product').each(function () {
            var inputs = $(this).find('.i-number');
            var total = 0;

            inputs.each(function () {
                total += parseInt($(this).val());
            });

            $(this).find('.single-product-qty').text(total);
        });
    }


    // calculate total quantity
    function totalQty() {
        var total = 0;

        $('.single-product-qty').each(function () {
            total += parseInt($(this).text());
        });

        $('#total-qty').text(total);
    }

    // calculate total quantity
    function totalDiscount() {
        var total = 0;

        $('.discount-value').each(function () {
            var currentValue = parseFloat($(this).text()).toFixed(2);
            total = parseFloat(total) + parseFloat(currentValue);
        });

        $('#total-discount').text(parseFloat(total).toFixed(2) + "€");
    }


    singleProductQty();
    totalQty();
    totalDiscount();

});