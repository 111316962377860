$(function() {

    if (!(Foundation.MediaQuery.atLeast('medium'))) {
        $('#blog-banner-cms img').removeClass('smooting-corners-blog-1 smooting-corners-blog-2');
      }

    if (Foundation.MediaQuery.is('medium only')) {
      $('#blog-banner-cms').attr('style','display:flex');
    }

    if (window.location.search != '') {
      if (document.getElementById('search-result') != undefined) {
        $('#search-result').removeClass('hide');
        document.getElementById('results-search').innerHTML = decodeURIComponent(GetUrlValue('search'));
      }
    } else {
      if ($('#search-result') != undefined) {
      $('#search-result').addClass('hide');
      }
    }

});