$(function () {
    if (window.location.search != "") {
        if (document.getElementById("shoptype") != undefined) {
            var type = GetUrlValue("business");
            document.getElementById("shoptype").value = "type:" + type;
        }
    }

    // show hide password recovery
    $(".js-toggle-show").on("click", function (event) {
        event.preventDefault();
        var $this = $(this);

        $($this.attr("data-show")).slideDown();
        $($this.attr("data-hide")).slideUp();
    });

    // password recovery form
    $("#password-recover-form").submit(function (event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr("method"),
            url: $form.attr("action"),
            data: $form.serialize(),
            dataType: "json",
            beforeSend: function (data) {
                $form.find(":input").prop("disabled", true);
            },
            success: function (data) {
                if (data.success) {
                    showMessageBox("success", msg["MSG_RECOVEREMAILSENT"]);
                }
                else {
                    showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
                }
            },
            failed: function (data) {
                showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
            },
            complete: function (data) {
                $form.find(":input").prop("disabled", false);
            },
        });
    });

    $("#subscribe-form").validate({
        errorPlacement: function (error, element) {},
        invalidHandler: () => {
            showMessageBox("danger", msg["ERR_MISSINGFIELDS"]);
        },
        submitHandler: form => {
            var $form = $(form);
    
            if ($("#password1").val() != $("#password2").val()) {
                showMessageBox("danger", msg["MSG_PASSWORD_NOT_EQUAL"]);
            }
            else {
                $.ajax({
                    type: $form.attr("method"),
                    url: $form.attr("action"),
                    data: $form.serialize(),
                    dataType: "json",
                    beforeSend: function (data) {
                        $form.find(":input").prop("disabled", true);
                    },
                    success: function (data) {
                        if (data.success) {
                            if (window.dataLayer) {
                                pushGTMEventWithCallback(500, {
                                    event: "sign_up",
                                    eventCallback: GTMCallback(function () {
                                        window.location =
                                            $form.data("redirectsuccess");
                                    }),
                                });
                            }
                            else {
                                window.location = $form.data("redirectsuccess");
                            }
                        }
                        else {
                            showMessageBox("danger", msg[data.errors.message]);
                        }
                    },
                    failed: function (data) {
                        showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
                    },
                    complete: function (data) {
                        $form.find(":input").prop("disabled", false);
                    },
                });
            }
        }
    });

    if ($("#hideHeaderLogin").length > 0) {
        $(".header-main").addClass("hide");
        $(".body-container").attr("style", "margin-top:0px");
    }

    $("a[href*=login-page]").click(function () {
        if (
            location.pathname.replace(/^\//, "") ==
                this.pathname.replace(/^\//, "") &&
            location.hostname == this.hostname
        ) {
            var $target = $(this.hash);
            $target =
                ($target.length && $target) ||
                $("[name=" + this.hash.slice(1) + "]");
            if ($target.length) {
                var targetOffset = $target.offset().top;
                $("html,body").animate({ scrollTop: targetOffset }, 1000);
                return false;
            }
        }
    });

    $("#password-new-form-email").validate({
        rules: {
            email: {
                required: true,
                email: true,
            },
        },
        messages: {
            email: {
                required: msg["ERR_MISSINGFIELDS"],
                email: msg["ERR_INVALIDMAIL"],
            },
        },
        submitHandler: function (form) {
            const $form = $(form);

            $.ajax({
                method: $form.attr("method"),
                url: $form.attr("action"),
                data: $form.serialize(),
                dataType: "json",
                success: function (data) {
                    if (data.success) {
                        showLargeMessageBox(
                            "success",
                            msg["MSG_RECOVERY_MAIL_SENT"]
                        );
                        $form.addClass("hide");
                        $("#password-new-form-token").removeClass("hide");
                        $("#email").val($("#recoveremail").val());
                    }
                    else {
                        showMessageBox(
                            "danger",
                            msg[data.errors.message] || msg["MSG_GENERIC_ERROR"]
                        );
                    }
                },
                error: function () {
                    showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
                },
            });
        },
    });

    $("#password-new-form-token").validate({
        rules: {
            token: {
                required: true,
            },
            password: {
                required: true,
            },
        },
        messages: {
            token: {
                required: msg["ERR_MISSINGFIELDS"],
            },
            password: {
                required: msg["ERR_MISSINGFIELDS"],
            },
        },
        submitHandler: function (form) {
            const $form = $(form);

            $.ajax({
                method: $form.attr("method"),
                url: $form.attr("action"),
                data: $form.serialize(),
                dataType: "json",
                success: function (data) {
                    if (data.success) {
                        showMessageBox("success", msg["MSG_PASSWORD_UPDATED"]);
                        setTimeout(() => {
                            let url = window.location.href.replace(
                                /https?:\/\//,
                                ""
                            );
                            let parts = url.split("/");
                            parts[2] = "login";
                            window.location.href = `https://${parts.join("/")}`;
                        }, 3000);
                    }
                    else {
                        if (data.errors.message == "fault") {
                            showMessageBox("danger", msg["ERR_WRONG_CODE"]);
                        }
                        else {
                            showMessageBox(
                                "danger",
                                msg[data.errors.message] ||
                                    msg["MSG_GENERIC_ERROR"]
                            );
                        }
                    }
                },
                error: function () {
                    showMessageBox("danger", msg["MSG_GENERIC_ERROR"]);
                },
            });
        },
    });

    if (getUrlParam("token") != undefined) {
        $("#token").val(getUrlParam("token"));
        $("#token").attr("readonly", "readonly");
    }
    if (getUrlParam("email") != undefined) {
        $("#password-new-form-email").addClass("hide");
        $("#password-new-form-token").removeClass("hide");
        $("#recoveremail").val(decodeURIComponent(getUrlParam("email")));
        $("#email").val(decodeURIComponent(getUrlParam("email")));
        $("#email").parents(".label-input").removeClass("hide");
    }
});

function GetUrlValue(VarSearch) {
    var SearchString = window.location.search.substring(1);
    var VariableArray = SearchString.split("&");
    for (var i = 0; i < VariableArray.length; i++) {
        var KeyValuePair = VariableArray[i].split("=");
        if (KeyValuePair[0] == VarSearch) {
            return KeyValuePair[1];
        }
    }
}
