$(function() {
    'use strict';

    $('#product-slider').slickLightbox({
        src: 'src',
        itemSelector: '.product-lightgallery > img'
    });


    $('#video-iframe').height($('#product-slider').width())

    $( window ).resize(function() {
        if ($('#video-iframe').length != 0) {
            document.getElementById("vimeo-" + $('#video-iframe').attr('data-video')).style.height = document.getElementsByClassName('thumb')[0].offsetHeight + 'px'
        }
    });

    $('.add-to-wishlist-catalog').click(function() {
        $(this).addClass('hide');
        $(this).parents('.product-item').find('.remove-from-wishlist-catalog').removeClass('hide');
    })

    $('.remove-from-wishlist-catalog').click(function() {
        $(this).addClass('hide');
        $(this).parents('.product-item').find('.add-to-wishlist-catalog').removeClass('hide');
    })

    vimeoLoadingThumb($('#video-iframe').attr('data-video'));
    if ($('#video-iframe').length != 0) {
        document.getElementById("vimeo-" + $('#video-iframe').attr('data-video')).style.height = document.getElementsByClassName('thumb')[0].offsetHeight + 'px'
    }

    $('#open-samples').on('click', function () {
        $('.sample-slick').slick({
            arrows: true,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            responsive: [
                {
                  breakpoint: 651,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    infinite: true,
                  }
                },
                {
                  breakpoint: 440,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                  }
                },
                {
                    breakpoint: 350,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true,
                    }
                }
              ]
          });

          $(".sample-image").hover(function(){
            var target = "tags-sample-" + $(this).attr('data-model');
            $('#'+target).toggleClass('hide');
            }, function(){
            var target = "tags-sample-" + $(this).attr('data-model');
            $('#'+target).toggleClass('hide');  
          });
    
          $('.delete-icon').on('click', function() {
            var form = $(this).closest('form');
            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: "qty_model_" + $(this).attr('data-model')+ "=0",
                dataType: 'json',
                success: function(data) {
                    if (!data.success) {
                        if (data.code == 'MIXED_INTANGIBLE') {
                            //showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                        } else if (data.code == 'MIXED_CART') {
                            //showMessageBox('danger', msg['MSG_MIXED_CART']);
                        } else if (data.code == 'LOGIN_REQUIRED') {
                            //location.href = '/' + $('html').attr('lang') + '/login';
                        } else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    } else {
                        updateCartCount(form);
                        //showMessageBox('success', msg['MSG_ADDEDTOCART']);
                        setTimeout(function() {
                            window.location.reload(true);
                        }, 500);
                    }
                },
                error: function(xhr, textStatus, errorThrown) {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
            });
        });
    });

    $('#close-modal').on('click', function () {
        $('#sample-modal').foundation('close');
    });

    $('.size').on('click', function() {
        if ($('.label-tech').length > 0) {
            $('.label-tech').first().addClass('hide');
            $('.label-tech').last().removeClass('hide');
        }
        $(this).parents('.size-div').siblings('.price-div').find('.price-row').addClass('hide');
        $(this).parents('.size-div').siblings('.price-div').find('.tax-price-row').addClass('hide');
        $(this).parents('.size-div').siblings('.price-div').find('.save-price-row').addClass('hide');
        $(this).parents('.size-div').siblings('.price-div').find('#price').addClass('hide');
        var id = $(this).attr('data-size');
        var wishlist_models =  $(this).parents('form').find('.wishlist-models').val().split(' ');
        var wishlist_present = wishlist_models.includes(id);
        if (wishlist_present) {
            $(this).parents('form').find('.div-already-wishlist').removeClass('hide');
            $(this).parents('form').find('.div-to-wishlist').addClass('hide');
        } else {
            $(this).parents('form').find('.div-already-wishlist').addClass('hide');
            $(this).parents('form').find('.div-to-wishlist').removeClass('hide');
        }
        $('#price-' + id).removeClass('hide');
        $('#tax-price-' + id).removeClass('hide');
        $('#save-price-' + id).removeClass('hide');

        setMaxAvailability(this);
    });

    $('.add-to-wishlist').on('click', function() {
        const model = $(this).children('input');
        addToWishlist(model, 1, msg['MSG_ADDED_TO_WISHLIST']);
    });
    $('.remove-from-wishlist').on('click', function() {
        const model = $(this).children('input');
        addToWishlist(model, 0, msg['MSG_REMOVED_FROM_WISHLIST']);
    });

    $('.change-model-wishlist').on('change', function() {
		const id = $(this).data("product");
		const val = $(this).val();
        const container = $(this).closest('.box-wishlist');

		container.find(`.product-item__price[data-product=${id}]`).addClass("hide");
		container.find(".product-item__price").addClass('hide').filter(`[data-model=${val}]`).removeClass("hide");
    
    })

    // Add to cart
    $('#catalog-container, #product-container').on('submit', '.addtocart-form', function(event) {
        event.preventDefault();
        const $form = $(this);
        const singleSize = $form.find('.nosize').val();
        const wishlist = $form.attr('wishlist');
        const remove = $form.attr('remove');

        if ($form.data('eu') == 'EXTRA-EU') {
            showLargeMessageBox('danger', msg['ERR_EXTRA_EU']);
            return;
        }

        let selected;
        let target = $form.closest('.size-div').find('.size-product input:checked').data('target');
        if (exists(target)) {
            selected = $(target).attr('name');
        }
        else {
            target = $('.size-product input:checked').data('target');
            selected = $(target).attr('name');
        }

        const noSizeSelected = $form.find('.no-size-selected');
        if (exists(noSizeSelected) && noSizeSelected.is(':checked') && not(wishlist) && not(singleSize)) {
                showMessageBox('danger', general_choosesize);
        }
        else {
            let data;
            let qty_model;

            if (singleSize == 'true') {
                qty_model = $form.find('.nosize-input').attr('name');
            }
            else if (selected) {
                qty_model = selected;
            }
            else {
                qty_model = $form.find('.firstModel').attr('value');
            }

            if (remove == 'true') {
                data = `${qty_model}=0`;
            }
            else {
                const value = $form.find('.i-number').val();
                data = `${qty_model}=${value}`;
            }
            
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: data,
                dataType: 'json',
                success: function(data) {
                    if (data.success) {
                        if (wishlist == 'true') {
                            if (remove == 'true') {
                                showMessageBox('success', msg['MSG_REMOVEDTOWISHLIST']);
                                $form.find('.div-to-wishlist').removeClass('hide');
                                $form.find('.div-already-wishlist').addClass('hide');
                            }
                            else {
                                showMessageBox('success', msg['MSG_ADDEDTOWISHLIST']);
                                $form.find('.div-to-wishlist').addClass('hide');
                                $form.find('.div-already-wishlist').removeClass('hide');
                            }
                        }
                        else {
                            rewixUpdateCart($form.find('[name="qty"]').parent());
                            updateCartCount($form);
                            const goToCart = () => window.location.href = '/current/cart';
                            showMessageBox('success', msg['MSG_ADDEDTOCART']);
                            $('#close-error').on('click', goToCart);
                            setTimeout(goToCart, messageBoxDuration);
                        }
                    }
                    else {
                        if (data.code == 'MIXED_INTANGIBLE') {
                            showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                        }
                        else if (data.code == 'MIXED_CART') {
                            showMessageBox('danger', msg['MSG_MIXED_CART']);
                        }
                        else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                            showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                        }
                        else if (data.code == 'LOGIN_REQUIRED') {
                            window.location.href = $form.data('nexturl');
                        }
                        else { 
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    }
                },
                error: function() {
                    if (exists('#contact-no-quantity')) {
                        $('#contact-no-quantity').foundation('open');
                    }
                    else {
                        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                    }
                },
                complete: function() {
                    if (wishlist == 'true') {
                        const [url] = $form.attr('action').split('?');
                        $form.attr('wishlist', 'false');
                        $form.attr('action', url);
                    }
                }
            });
        }
    });

    $('#catalog-container, #product-container').on('submit', '.addtocart-form-sample', function(event) {
        event.preventDefault();
        var form = $(this);

        if ($('#sample_id_value').attr('name') == "qty_model_") {
            showMessageBox('danger', msg['MSG_SAMPLE_NOT_FOUND']);
        }
        else {
            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: form.serialize(),
                dataType: 'json',
                success: function(data) {
                    if (!data.success) {
                        if (data.code == 'MIXED_INTANGIBLE') {
                            showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                        }
                        else if (data.code == 'MIXED_CART') {
                            showMessageBox('danger', msg['MSG_MIXED_CART']);
                        }
                        else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                            showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                        }
                        else if (data.code == 'LOGIN_REQUIRED') {
                            location.href = '/' + $('html').attr('lang') + '/login';
                        }
                        else {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        }
                    }
                    else {
                        rewixUpdateCart(form);
                        updateCartCount(form);
                        showMessageBox('success', msg['MSG_ADDEDTOCART']);
                        setTimeout(function() {
                            window.location.reload(true);
                        }, 500000);
                       // location.reload(true);
                    }
                },
                error: function() {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
            });
        }
    });

    $('#sample-modal').on('closed.zf.reveal', function() {
        $('.sample-slick').slick('unslick');
    });

    $( ".btn-wishlist-to-cart" ).click(function() {
        var product = $(this).parents('.box-wishlist');
        console.log(product);
        event.preventDefault();
        var oldModel = product.find('.size-org').val();
        var newModel = product.find('select[name=model_id]')[0].options[product.find('select[name=model_id]')[0].selectedIndex].value;

        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/addtocart?wishlist=true',
            data: 'qty_model_' + oldModel + '=0',
            success: function(data) {
                $.ajax({
                    type: 'POST',
                    url: '/restful/shopping/public/checkout/addtocart',
                    data: 'qty_model_' + newModel + '=1',
                    success: function(data) {
                        showMessageBox('success', msg['MSG_ADDEDTOCART']);
                    },
                    error: function(xhr, textStatus, errorThrown) {
                        showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                    }
                });
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

});

function updateCartCount(form, callback) {
    $('.header-utilities__wishlist').parent().load(window.location + '?' + new Date().getTime() + ' .header-utilities__wishlist:lt(1)', function() {
        if (callback) {
            callback();
        }
    });
}

function vimeoLoadingThumb(id){    
    var url = "https://vimeo.com/api/v2/video/" + id + ".json?callback=showThumb";

    var id_img = "#vimeo-" + id;

    var script = document.createElement( 'script' );
    script.src = url;

    $(id_img).before(script);
}


function showThumb(data){
    var id_img = "#vimeo-" + data[0].id;
    $(id_img).attr('src',data[0].thumbnail_medium);
}

function loadQtyInputs() {
    $('.i-number').each(function() {
        const $this = $(this);

        if (!$this.data('initialized')) {
            $('<a class="i-number-btn i-number-minus" href="#" role="button" tabindex="-1"><img src="/skins/b2bopificio/images/icons/qty-minus.svg"/></a>')
                .insertBefore($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value - 1);
                });
            
            $('<a class="i-number-btn i-number-plus" href="#" role="button" tabindex="-1"><img src="/skins/b2bopificio/images/icons/qty-plus.svg"/></a>')
                .insertAfter($this)
                .on('click', function(event) {
                    event.preventDefault();
                    const value = getCurrentValue($this);
                    checkValue(value + 1);
                });
    
            $this.on('keydown input', function(event) {
                const value = getCurrentValue($this);
                const key = (event.key || '').toUpperCase();

                if (key == 'ENTER') {
                    event.preventDefault();
                    $this.closest('form').trigger('submit');
                }
                else if (key == 'ARROWUP') {
                    checkValue(value + 1);
                }
                else if (key == 'ARROWDOWN') {
                    checkValue(value - 1);
                }
                else {
                    checkValue(value);
                }
            });

            $this.data('initialized', true);
    
            function getCurrentValue($this) {
                return $this.val().toString().replace(/[^0-9]/g, '') >> 0;
            }
    
            function checkValue(value) {
                const min = $this.data('min') >> 0;
                
                if (value < min) {
                    $this.val(min);
                }
                else {
                    $this.val(value);
                }
                $this.trigger('change');
            }

            checkIfOutOfStock();
            function checkIfOutOfStock() {
                const current = $this.val();
                const max = Number.MAX_SAFE_INTEGER;

                if (current <= max && max > 0) {
                    $this.css('color', '');
                    if (exists('.contact-no-quantity')) {
                        $('.contact-no-quantity').addClass('hide');
                    }
                }
                else {
                    $this.css('color', 'red');
                    if (exists('.contact-no-quantity')) {
                        $('.contact-no-quantity').removeClass('hide');
                    }
                }
            }

            setMaxAvailability($this);
        }
    });
}

function setMaxAvailability(element) {
    const $form = $(element).closest('form');
    const input = $form.find('.i-number');
    const size = $form.find('[name="size"]:checked');
    const nosize = $form.find('.nosize');
    const availability = exists(size) ? size.data('availability') : nosize.data('availability');
    const matomo = exists(size) ? size.data('matomo') : nosize.data('matomo');
    
    input.data('max', availability).data('matomo', matomo);
    input.trigger('input');
}